// contact page Phone
export const mobile = [
  {
    id: 1,
    title: "Phone",
    mobile: "+919921009258 ",
    mobile1: " +919921001303",
  },
];

export const email = [
  {
    id: 1,
    title: "Email",
    email: "marketing@advancecnctechnologies.com",
  },
  // contact page address
];
export const address = [
  {
    id: 1,
    title: "Address",
    address:
      "Paras Industrial Estate,PlotNo-71/1B/06 T General Block,MIDC Bhosari,Pune-4411026, Maharashtra,India",
  },
];
